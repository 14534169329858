import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import panelRoutesData from './config/panel-routes.json';
// COMPONENTS
import LayoutComponent from './components/Layout';
// PAGES
import LoginPage from './pages/auth';
import EntityPage from './pages/entity';
import EmptyPage from './pages/empty';
import Dashboard from './pages/dashboard';
import PeoplePage from './pages/people';
import TeamsPage from './pages/teams';

const App = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getIdTokenClaims,
  } = useAuth0();
  return (
    <Router>
      {!isLoading && !isAuthenticated
            && (
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <LoginPage
                    isLoading={isLoading}
                    error={error}
                    getIdTokenClaims={getIdTokenClaims}
                    isAuthenticated={isAuthenticated}
                    user={user}
                    logout={logout}
                    loginWithRedirect={loginWithRedirect}
                  />
                )}
              />
              <Route path="*" render={EmptyPage} />
            </Switch>
            )}
      {!isLoading && isAuthenticated && (
      <LayoutComponent
        logout={logout}
        menues={panelRoutesData}
      >
        <Switch>
          <Route exact path="/" render={() => <Dashboard getClaims={getIdTokenClaims} />} />
          <Route exact path="/people" render={() => <PeoplePage />} />
          <Route exact path="/entity" render={() => <EntityPage />} />
          <Route exact path="/teams" render={() => <TeamsPage />} />
          <Route path="*" render={EmptyPage} />
        </Switch>
      </LayoutComponent>
      )}
    </Router>
  );
};

export default App;
