import React from 'react';
import { Input } from 'antd';
import * as _ from 'lodash';
// STYLES
import '../teams.scoped.scss';

export const ViewModalHeader = ({
  selectedRowData,
  teamsType,
}) => (
  <div className="view-modal-header-wrapper">
    <span className="capitalize">{teamsType}</span>
    {' '}
    -
    {selectedRowData.association}
  </div>
);

export const UpdateModalBody = ({
  teamsType,
  setStateUpdate,
  setUsersUpdate,
  stateUpdate,
  usersUpdate,
}) => {
  switch (teamsType) {
    case 'active':
      return (
        <div className="view-modal-body-wrapper">
          <p>
            <b>
              State:
              <Input value={stateUpdate} onChange={(e) => setStateUpdate(e.target.value)} />
            </b>
          </p>
          {/* will be enabled in future */}
          {/* <p>
            <b>Users:</b>
            {' '}
            <Input value={usersUpdate} onChange={(e) => setUsersUpdate(e.target.value)} />
          </p> */}
        </div>
      );
    case 'pending':
      return (
        <div className="view-modal-body-wrapper">
          <p>
            <b>
              State:
              <Input value={stateUpdate} onChange={(e) => setStateUpdate(e.target.value)} />
            </b>
          </p>
          {/* will be enabled in future */}
          {/* <p>
            <b>Users:</b>
            {' '}
            <Input value={usersUpdate} onChange={(e) => setUsersUpdate(e.target.value)} />
          </p> */}
        </div>
      );
    default:
      return <></>;
  }
};

export const ModalBody = ({
  teamsType,
  selectedRowData,
  modalOpenedBy,
  setStateUpdate,
  setUsersUpdate,
  stateUpdate,
  usersUpdate
}) => {
  switch (modalOpenedBy) {
    case 'update':
      return (
        <UpdateModalBody
          teamsType={teamsType}
          setStateUpdate={setStateUpdate}
          setUsersUpdate={setUsersUpdate}
          stateUpdate={stateUpdate}
          usersUpdate={usersUpdate}
        />
      );
    default:
      return <></>;
  }
};

export const ModalHeader = ({
  modalOpenedBy,
  teamsType,
  selectedRowData,
}) => {
  switch (modalOpenedBy) {
    case 'update':
      return (
        <ViewModalHeader
          teamsType={teamsType}
          selectedRowData={selectedRowData}
        />
      );
    default:
      return <></>;
  }
};
