import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Row, Col, Button, Space, Popconfirm,
} from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
// ELEMENTS
import { ModalBody, ModalHeader } from './elements/modal';
// SERVICES
import { getTeams, updateTeam, deleteTeam } from '../../services/teams';
// COMPONENTS
import ModalComponent from '../../components/Modal/modal';
import TableComponent from '../../components/Table/table';
// STYLES
import './teams.scoped.scss';

const PeoplePage = ({
  history,
}) => {
  const params = new URLSearchParams(history.location.search);
  const teamsType = params.get('type');
  const filterOptions = [
      {label: 'Artist', value: {association_type: 'artist'}},
      {label: 'Brand', value: {association_type: 'brand'}},
      {label: 'All', value: {}},
  ]
  // STATES
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataSource, setDataSource]: any = useState([]);
  const [modalOpenedBy, setModalOpenedBy] = useState('');
  const [selectedRowData, setSelectedRowData]: any = useState({});
  const [stateUpdate, setStateUpdate] = useState('');
  const [usersUpdate, setUsersUpdate] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('All');

  const mapTeamsData = (teamsData:any) => {
    const data = teamsData.map((team:any) => ({
        ...team,
        key: team.id,
        noOfUsers: team.users.length
    }));
    setDataSource(data);
  };

  useEffect(() => {
    if(teamsType){
        // history.push('/teams?type=active');
        getTeams({filter: {state: teamsType}}).then((res) => {
          mapTeamsData(res);
        });
    }
  }, [teamsType]);

  const openModal = (type) => {
    setModalOpenedBy(type);
    setIsModalVisible(true);
  };

  const actions = (record, type) => {
    switch (type) {
      case 'update':
        setStateUpdate(record.state);
        // setUsersUpdate(record.users);
        openModal(type);
        setSelectedRowData(record);
        break;
      case 'delete':
        deleteTeam({ id: record.id })
        .then((res) => {
            const data = [...dataSource];
            setDataSource(data.filter((item) => item.key !== record.key));
        })
        .catch((err) => {
            // TODO: Later on remove console
            console.log('false', err);
        });
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'key',
    },
    // {
    //   title: 'Association',
    //   dataIndex: 'association',
    //   key: 'key',
    // },
    {
      title: 'Type',
      dataIndex: 'association_type',
      key: 'key',
      render: (text, record) => <span className='association-type'>{record.association_type}</span>
    },
    {
        title: '# Users',
        dataIndex: 'noOfUsers',
        key: 'key'
    },
    {
      title: 'Actions',
      key: 'key',
      render: (text, record) => (
        <Space size="small">
          <span className="actions" onClick={() => actions(record, 'update')}>Update</span>
          <Popconfirm title="Sure to delete?" onConfirm={() => actions(record, 'delete')}>
            <span className="actions">Delete</span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const filterByTeamType = async (filterObj:any) => {
    const teamsData = await getTeams({filter: {state: teamsType, ...filterObj.value}});
    setSelectedFilter(filterObj.label);
    mapTeamsData(teamsData);
  }

  const redirectToPath = async (path:string) => {
    history.push(path);
    const teamsData = await getTeams({filter: {state: path.includes('active') ? 'active' : 'pending'}});
    mapTeamsData(teamsData);
  };

  const handleOk = async () => {
    try{
        await updateTeam({
          id: selectedRowData.id,
          data: {
            state: stateUpdate,
            // user: usersUpdate,
          },
        });
        const updateDataSource = dataSource.map((data) => {
            if (teamsType === 'active' && selectedRowData.id === data.id) {
                data.state = stateUpdate;
                data.users = usersUpdate;
            } else if (teamsType === 'pending' && selectedRowData.id === data.id) {
                data.state = stateUpdate;
                data.users = usersUpdate;
            }
            return data;
        });
        setDataSource(updateDataSource);
    }catch(err){
        // TODO: removed later on
        console.log(err);
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="teams-wrapper">
      <Row justify="space-between">
        <Col>
          <div className="header" data-testid="header">Teams</div>
        </Col>
        <Col data-testid="create-new">
          <ModalComponent
            isModalVisible={isModalVisible}
            header={(
              <ModalHeader
                selectedRowData={selectedRowData}
                modalOpenedBy={modalOpenedBy}
                teamsType={teamsType}
              />
            )}
            body={(
              <ModalBody
                modalOpenedBy={modalOpenedBy}
                teamsType={teamsType}
                selectedRowData={selectedRowData}
                setStateUpdate={setStateUpdate}
                setUsersUpdate={setUsersUpdate}
                stateUpdate={stateUpdate}
                usersUpdate={usersUpdate}
              />
            )}
            handleOk={handleOk}
            handleCancel={handleCancel}
            hideOkButton={['view'].includes(modalOpenedBy)}
            hideCancelButton={['view'].includes(modalOpenedBy)}
          />
        </Col>
      </Row>
      <Row justify="end" style={{ paddingTop: '20px' }}>
        <Col>
            <div>
                <span>Filter by team type:</span>
                {filterOptions.map(filterObj => (
                    <span 
                        className={`filters actions underline ${selectedFilter === filterObj.label && 'filter-selected'}`} 
                        key={filterObj.label} 
                        onClick={() => filterByTeamType(filterObj)}
                    >
                        {filterObj.label}
                    </span>
                ))}
            </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={4}>
          <span className={`tabs-txt ${teamsType === 'active' && 'underline'}`} onClick={() => redirectToPath('/teams?type=active')}>ACTIVE</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <span className={`tabs-txt ${teamsType === 'pending' && 'underline'}`} onClick={() => redirectToPath('/teams?type=pending')}>PENDING</span>
        </Col>
      </Row>
      <br />
      {teamsType && dataSource && (
        <div className="artists-wrapper">
          <TableComponent columnsData={columns} dataSource={dataSource} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  totalReq: state.loading.totalRequest,
});

export default connect(mapStateToProps)(withRouter(PeoplePage));
