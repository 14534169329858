import React from 'react';
import { Input } from 'antd';
import * as _ from 'lodash';
// STYLES
import '../people.scoped.scss';

export const CreateNewModalBody = ({
  peopleType,
  createModalObject,
}) => {
  const modalFieldType = {
    admins: ['name', 'email', 'role'],
    users: ['name', 'email', 'role'],
  };
  return (
    <div className="modal-body-wrapper">
      {peopleType && modalFieldType[peopleType].map((modalField) => (
        <div className="field-wrapper" key={modalField}>
          <div className="input-lable">{modalField}</div>
          <Input name={modalField} onChange={createModalObject} placeholder={`Enter your ${modalField}`} />
        </div>
      ))}
    </div>
  );
};

export const CreateNewModalHeader = ({ peopleType }) => (
  <div>
    Create new
    {peopleType}
  </div>
);

export const ViewModalBody = ({
  selectedRowData,
  peopleType,
}) => {
  switch (peopleType) {
    case 'admins':
      return (
        <div className="view-modal-body-wrapper">
          <p>
            <b>
              Name:
              {selectedRowData.name || '--'}
            </b>
          </p>
          <p>
            <b>Email:</b>
            {' '}
            {selectedRowData.email || '--'}
          </p>
        </div>
      );
    case 'users':
      return (
        <div className="view-modal-body-wrapper">
          <p>
            <b>
              Name:
              {selectedRowData.name || '--'}
            </b>
          </p>
          <p>
            <b>Email:</b>
            {' '}
            {selectedRowData.email || '--'}
          </p>
        </div>
      );
    default:
      return <></>;
  }
};

export const ViewModalHeader = ({
  selectedRowData,
  peopleType,
}) => (
  <div className="view-modal-header-wrapper">
    <span className="capitalize">{peopleType}</span>
    {' '}
    -
    {selectedRowData.name}
  </div>
);

export const UpdateModalBody = ({
  peopleType,
  setNameUpdate,
  setEmailUpdate,
  setRoleUpdate,
  nameUpdate,
  emailUpdate,
  roleUpdate
}) => {
  switch (peopleType) {
    case 'admins':
      return (
        <div className="view-modal-body-wrapper">
          <p>
            <b>
              Name:
              <Input value={nameUpdate} onChange={(e) => setNameUpdate(e.target.value)} />
            </b>
          </p>
          <p>
            <b>Email:</b>
            {' '}
            <Input value={emailUpdate} onChange={(e) => setEmailUpdate(e.target.value)} />
          </p>
          <p>
            <b>Role:</b>
            {' '}
            <Input value={roleUpdate} onChange={(e) => setRoleUpdate(e.target.value)} />
          </p>
        </div>
      );
    case 'users':
      return (
        <div className="view-modal-body-wrapper">
          <p>
            <b>
              Name:
              <Input value={nameUpdate} onChange={(e) => setNameUpdate(e.target.value)} />
            </b>
          </p>
          <p>
            <b>Email:</b>
            {' '}
            <Input value={emailUpdate} onChange={(e) => setEmailUpdate(e.target.value)} />
          </p>
          <p>
            <b>Email:</b>
            {' '}
            <Input value={roleUpdate} onChange={(e) => setRoleUpdate(e.target.value)} />
          </p>
        </div>
      );
    default:
      return <></>;
  }
};

export const ModalBody = ({
  peopleType,
  createModalObject,
  selectedRowData,
  modalOpenedBy,
  setNameUpdate,
  setEmailUpdate,
  setRoleUpdate,
  nameUpdate,
  emailUpdate,
  roleUpdate
}) => {
  switch (modalOpenedBy) {
    case 'createNewButton':
      return (
        <CreateNewModalBody
          peopleType={peopleType}
          createModalObject={createModalObject}
        />
      );
    case 'view':
      return (
        <ViewModalBody
          selectedRowData={selectedRowData}
          peopleType={peopleType}
        />
      );
    case 'update':
      return (
        <UpdateModalBody
          roleUpdate={roleUpdate}
          setRoleUpdate={setRoleUpdate}
          peopleType={peopleType}
          setNameUpdate={setNameUpdate}
          setEmailUpdate={setEmailUpdate}
          nameUpdate={nameUpdate}
          emailUpdate={emailUpdate}
        />
      );
    default:
      return <></>;
  }
};

export const ModalHeader = ({
  modalOpenedBy,
  peopleType,
  selectedRowData,
}) => {
  switch (modalOpenedBy) {
    case 'createNewButton':
      return (
        <CreateNewModalHeader
          peopleType={peopleType}
        />
      );
    case 'view':
      return (
        <ViewModalHeader
          peopleType={peopleType}
          selectedRowData={selectedRowData}
        />
      );
    case 'update':
      return (
        <ViewModalHeader
          peopleType={peopleType}
          selectedRowData={selectedRowData}
        />
      );
    default:
      return <></>;
  }
};
