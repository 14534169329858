import React from 'react';
import { Card, Button } from 'antd';
import './auth.scoped.scss';

interface LoginPageProp {
    isLoading: boolean,
    error: any,
    getIdTokenClaims: any,
    isAuthenticated: boolean,
    user: any,
    logout: any,
    loginWithRedirect: any,
}

const LoginPage = ({
  isLoading,
  error,
  getIdTokenClaims,
  isAuthenticated,
  user,
  logout,
  loginWithRedirect,
}: LoginPageProp) => {
  if (isLoading) {
    return <div data-testid="loading-txt">Loading...</div>;
  }
  if (error) {
    return (
      <div data-testid="error-msg">
        Oops...
        {error.message}
      </div>
    );
  }

  const getTokens = async () => {
    const claims = await getIdTokenClaims();
    localStorage.setItem('user', claims);
  };

  if (isAuthenticated && user) {
    return (
      <div>
        <div data-testid="user-name">
          Hello
          {user.name}
        </div>
        <button onClick={() => getTokens()}>Tokens</button>
        <button onClick={() => logout({ returnTo: window.location.origin })}>
          Log out
        </button>
      </div>
    );
  }
  return (
    <div className="auth-wrapper">
      <Card className="auth-body">
        <div className="login-title-txt">Welcome to Cuttime</div>
        <Button
          type="primary"
          className="login-btn"
          data-testid="login-btn"
          onClick={loginWithRedirect}
        >
          Sign In
        </Button>
      </Card>
    </div>
  );
};

export default LoginPage;
