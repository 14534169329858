import React from 'react';
import { Table } from 'antd';
// STYLES
import './table.scoped.scss';

const TableComponent = ({
  dataSource = [],
  columnsData = [],
}) => (
  <Table
    data-testid="table"
    bordered
    dataSource={dataSource}
    scroll={{ y: 400 }}
    columns={columnsData}
  />
);

export default TableComponent;
