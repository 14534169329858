import React from 'react';
import { Layout, Menu } from 'antd';
import { withRouter } from 'react-router-dom';
// SYTLES
import './sidebar.scoped.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface SideBarComponentProp {
    collapsed: boolean,
    menues: any,
    history?: any
}

const SidebarComponent = ({
  collapsed,
  menues,
  history,
}: SideBarComponentProp) => {
  const redirectToPath = (path) => {
    history.push(path);
  };

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo" />
      <Menu theme="dark" mode="inline" defaultSelectedKeys={['Dashboard0']}>
        {menues && menues.map((menu, idx) => (menu.isSubMenu
          ? (
            <SubMenu key={menu.name + idx} title={menu.name} icon={<span className="material-icons">{menu.icon}</span>}>
              {menu.subMenues.map((subMenu) => (
                <Menu.Item key={subMenu.name + idx} onClick={() => redirectToPath(subMenu.path)}>{subMenu.name}</Menu.Item>
              ))}
            </SubMenu>
          )
          : (
            <Menu.Item key={menu.name + idx} onClick={() => redirectToPath(menu.path)} icon={<span className="material-icons">{menu.icon}</span>}>
              {menu.name}
            </Menu.Item>
          )))}
      </Menu>
    </Sider>
  );
};

export default withRouter(SidebarComponent);
