import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Row, Col, Button, Space, Popconfirm,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import _ from 'lodash';
// ELEMENTS
import { ModalBody, ModalHeader } from './elements/modal';
// SERVICES
import { getUsers, createUser, updateUser, deleteUser } from '../../services/people';
// COMPONENTS
import ModalComponent from '../../components/Modal/modal';
import TableComponent from '../../components/Table/table';
// STYLES
import './people.scoped.scss';
import SearchInputComponent from '../../components/Input/search-input';

const PeoplePage = ({
  history,
  filterKeys = ['name'],
  loading,
  totalReq,
}) => {
  const params = new URLSearchParams(history.location.search);
  const peopleType = params.get('type');
  // STATES
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newPeopleObj, setNewPeopleObj]: any = useState({});
  const [dataSource, setDataSource]: any = useState([]);
  const [peopleFilteredData, setPeopleFilteredData] = useState([]);
  const [hasSearchValue, setHasSearchValue] = useState(false);
  const [modalOpenedBy, setModalOpenedBy] = useState('');
  const [selectedRowData, setSelectedRowData]: any = useState({});
  const [nameUpdate, setNameUpdate] = useState('');
  const [emailUpdate, setEmailUpdate] = useState('');
  const [roleUpdate, setRoleUpdate] = useState('');

  const mapToPeopleData = (peopleData) => {
    const data = peopleData.map((user) => ({
      key: user.id,
      name: user.nickname,
      id: user.id,
      role: user.role,
      ...user,
    }));
    setDataSource(data);
  };

  useEffect(() => {
    if(peopleType){
      const filterQuery = peopleType === 'admins' ? {filter: {role: 'admin'}} : {}
      getUsers(filterQuery).then((res) => {
        mapToPeopleData(res);
      });
    }
  }, [peopleType]);

  const openModal = (type) => {
    setModalOpenedBy(type);
    setIsModalVisible(true);
  };

  const actions = (record, type) => {
    switch (type) {
      case 'view':
        openModal(type);
        setSelectedRowData(record);
        break;
      case 'update':
        setEmailUpdate(record.email);
        setNameUpdate(record.name);
        setRoleUpdate(record.role);
        openModal(type);
        setSelectedRowData(record);
        break;
      case 'delete':
        deleteUser({ id: record.id })
        .then((res) => {
          const data = [...dataSource];
          setDataSource(data.filter((item) => item.key !== record.key));
        })
        .catch((err) => {
        // TODO: Later on remove console
          console.log('false', err);
        });
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'key',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'key',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'key',
      render: (text, record) => <span style={{textTransform: 'capitalize'}}>{record.role}</span>
    },
    {
      title: 'Action',
      key: 'key',
      render: (text, record) => (
        <Space size="small">
          <span className="actions" onClick={() => actions(record, 'update')}>Update</span>
          <Popconfirm title="Sure to delete?" onConfirm={() => actions(record, 'delete')}>
            <span className="actions">Delete</span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const redirectToPath = async (path) => {
    setHasSearchValue(false);
    setPeopleFilteredData([]);
    history.push(path);
    const filterQuery = path.includes('admins') ? {filter: {role: 'admin'}} : {}
    const entityData = await getUsers(filterQuery);
    mapToPeopleData(entityData);
  };

  const handleOk = async () => {
    if (modalOpenedBy === 'update') {
      try{
        const updateRes = await updateUser({
          id: selectedRowData.id,
          data: {
            nickname: nameUpdate,
            email: emailUpdate,
            role: roleUpdate
          },
        });
        if(updateRes){
          const updateDataSource = dataSource.map((data) => {
            if (peopleType === 'admins' && selectedRowData.id === data.id) {
              data.name = nameUpdate;
              data.nickname = nameUpdate;
              data.email = emailUpdate;
              data.role = roleUpdate;
            } else if (peopleType === 'users' && selectedRowData.id === data.id) {
              data.name = nameUpdate;
              data.nickname = nameUpdate;
              data.email = emailUpdate;
              data.role = roleUpdate;
            }
            return data;
          });
          setDataSource(updateDataSource);
        }
      }catch(err){
        // TODO: removed later on
        console.log(err.message);
      }
    } else {
      try{
        const userCreateRes = await createUser({data: {
          nickname: newPeopleObj.name,
          email: newPeopleObj.email,
          role: newPeopleObj.role,
          team_id: 'c844d6e5-e34b-4ac3-a2c0-2d65f0ff3aad'
        }});
        userCreateRes && setDataSource([...dataSource, { ...userCreateRes, ...newPeopleObj }]);
      }catch(err){
        // TODO: Later on remove console
        console.log(err.message);
      }
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setNewPeopleObj({});
    setIsModalVisible(false);
  };

  const createModalObject = (e) => {
    const data = {
      [e.target.name]: e.target.value,
    };
    setNewPeopleObj({ ...newPeopleObj, ...data });
  };
  const peopleCollection = hasSearchValue || peopleFilteredData.length > 0 ? peopleFilteredData : dataSource;
  return (
    <div className="entities-wrapper">
      <Row justify="space-between">
        <Col>
          <div className="header" data-testid="header">People</div>
        </Col>
        <Col data-testid="create-new">
          <Button className="create-new" type="primary" onClick={() => openModal('createNewButton')}>Create New</Button>
          <ModalComponent
            isModalVisible={isModalVisible}
            header={(
              <ModalHeader
                selectedRowData={selectedRowData}
                modalOpenedBy={modalOpenedBy}
                peopleType={peopleType}
              />
            )}
            body={(
              <ModalBody
                modalOpenedBy={modalOpenedBy}
                peopleType={peopleType}
                createModalObject={createModalObject}
                selectedRowData={selectedRowData}
                setNameUpdate={setNameUpdate}
                setEmailUpdate={setEmailUpdate}
                setRoleUpdate={setRoleUpdate}
                nameUpdate={nameUpdate}
                emailUpdate={emailUpdate}
                roleUpdate={roleUpdate}
              />
            )}
            handleOk={handleOk}
            handleCancel={handleCancel}
            hideOkButton={['view'].includes(modalOpenedBy)}
            hideCancelButton={['view'].includes(modalOpenedBy)}
          />
        </Col>
      </Row>
      <Row justify="end" style={{ paddingTop: '20px' }}>
        <Col>
          <SearchInputComponent
            collection={dataSource}
            setFilter={setPeopleFilteredData}
            hasValue={setHasSearchValue}
            filterFor={filterKeys}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={4}>
          <span className={`tabs-txt ${peopleType === 'admins' && 'underline'}`} onClick={() => redirectToPath('/people?type=admins')}>ADMINS</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <span className={`tabs-txt ${peopleType === 'users' && 'underline'}`} onClick={() => redirectToPath('/people?type=users')}>USERS</span>
        </Col>
      </Row>
      <br />
      {peopleType && peopleCollection && (
        <div className="artists-wrapper">
          <TableComponent columnsData={columns} dataSource={peopleCollection} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  totalReq: state.loading.totalRequest,
});

export default connect(mapStateToProps)(withRouter(PeoplePage));
