import { ENTITIES_SETSTATE } from './entitiesTypes';

const intialUserState = {
};

const entitiesReducer = (state = intialUserState, action: any) => {
  switch (action.type) {
    case ENTITIES_SETSTATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default entitiesReducer;
