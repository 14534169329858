import React, { useEffect, useState } from 'react';

const Dashboard = ({
  getClaims,
}) => {
  const [email, setEmail] = useState('');
  useEffect(() => {
    getClaims().then((claims) => {
      localStorage.setItem('user', JSON.stringify(claims));
      setEmail(claims.email);
    });
    // eslint-disable-next-line
    }, []);

  return (
    <div className="dashboard">
      Welcome
      {' '}
      {email}
    </div>
  );
};

export default Dashboard;
