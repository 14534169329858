import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Row, Col, Button, Space, Popconfirm,
} from 'antd';
import _ from 'lodash';
// ELEMENTS
import { ModalBody, ModalHeader } from './elements/modal';
// SERVICES
import {
  getEntity, deleteEntity, createEntity, updateEntity,
} from '../../services/entity';
// COMPONENTS
import ModalComponent from '../../components/Modal/modal';
import TableComponent from '../../components/Table/table';
// STYLES
import './entity.scoped.scss';
import SearchInputComponent from '../../components/Input/search-input';

const EntityPage = ({
  history,
  filterKeys = ['name']
}) => {
  const params = new URLSearchParams(history.location.search);
  const entityType = params.get('type');
  // STATES
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newEntityObj, setNewEntityObj] = useState({});
  const [dataSource, setDataSource]: any = useState([]);
  const [entityFilteredData, setEntityFilteredData] = useState([]);
  const [hasSearchValue, setHasSearchValue] = useState(false);
  const [modalOpenedBy, setModalOpenedBy] = useState('');
  const [selectedRowData, setSelectedRowData]:any = useState({});
  const [nameUpdate, setNameUpdate] = useState('');
  const [bioUpdate, setBioUpdate] = useState('');
  const [websiteUpdate, setWebsiteUpdate] = useState('');
  const [socialsUpdate, setSocialsUpdate] = useState({
    twitter: '',
    youtube: '',
    facebook: '',
    linkedin: '',
    pinterest: '',
  });

  const mapToEntityData = (entityData) => {
    const data = entityData.map((artist) => ({
      key: artist.id,
      name: artist.name,
      id: artist.id,
      status: '--',
      ...artist,
    }));
    setDataSource(data);
  };

  useEffect(() => {
    if(entityType){
      getEntity(entityType).then((res) => {
        mapToEntityData(res);
      });
    }
  }, [entityType]);

  const openModal = (type) => {
    setModalOpenedBy(type);
    setIsModalVisible(true);
  };

  const actions = (record:any, type:string) => {
    switch (type) {
      case 'view':
        openModal(type);
        setSelectedRowData(record);
        break;
      case 'update':
        if (entityType === 'artists') {
          setBioUpdate(record.biography);
        } else {
          setWebsiteUpdate(record.website);
          const socialsObj:any = {};
          _.each(socialsUpdate, (value, key) => {
            socialsObj[key] = record.socials[key];
          });
          setSocialsUpdate(socialsObj);
        }
        setNameUpdate(record.name);
        openModal(type);
        setSelectedRowData(record);
        break;
      case 'delete':
        deleteEntity({ entityType, id: record.id })
          .then((res) => {
            const data = [...dataSource];
            setDataSource(data.filter((item) => item.key !== record.key));
          })
          .catch((err) => {
          // TODO: Later on remove console
            console.log('false', err);
          });
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'key',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'key',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'key',
    },
    {
      title: 'Action',
      key: 'key',
      render: (text, record) => (
        <Space size="small">
          <span className="actions" onClick={() => actions(record, 'view')}>View</span>
          <span className="actions" onClick={() => actions(record, 'update')}>Update</span>
          <Popconfirm title="Sure to delete?" onConfirm={() => actions(record, 'delete')}>
            <span className="actions">Delete</span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const redirectToPath = async (path) => {
    setHasSearchValue(false);
    setEntityFilteredData([]);
    history.push(path);
    const entityType = path.includes('artists') ? 'artists' : 'brands';
    const entityData = await getEntity(entityType);
    mapToEntityData(entityData);
  };

  const handleOk = async () => {
    if (modalOpenedBy === 'update') {
      try {
        await updateEntity({
          entityType,
          id: selectedRowData.id,
          data: {
            name: nameUpdate,
            ...(entityType === 'artists' ? { biography: bioUpdate } : { website: websiteUpdate, socials: socialsUpdate }),
          },
        });
        const updateDataSource = dataSource.map((data) => {
          if (entityType === 'artists' && selectedRowData.id === data.id) {
            data.name = nameUpdate;
            data.biography = bioUpdate;
          } else if (entityType === 'brands' && selectedRowData.id === data.id) {
            data.name = nameUpdate;
            data.website = websiteUpdate;
            data.socials = socialsUpdate;
          }
          return data;
        });
        setDataSource(updateDataSource);
      } catch (err) {
        // TODO: Later on remove console
        console.log(err);
      }
    } else {
      try {
        const createEntityRes = await createEntity({
          entityType,
          data: newEntityObj,
        });
        setDataSource([...dataSource, { ...createEntityRes, ...newEntityObj }]);
      } catch (err) {
        // TODO: Later on remove console
        console.log(err);
      }
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setNewEntityObj({});
    setIsModalVisible(false);
  };

  const createModalObject = (e) => {
    const data = {
      [e.target.name]: e.target.value,
    };
    setNewEntityObj({ ...newEntityObj, ...data });
  };
  const entityCollection = hasSearchValue || entityFilteredData.length > 0 ? entityFilteredData : dataSource;
  return (
    <div className="entities-wrapper">
      <Row justify="space-between">
        <Col>
          <div className="header" data-testid="header">Entities</div>
        </Col>
        <Col data-testid="create-new">
          <Button className="create-new" type="primary" onClick={() => openModal('createNewButton')}>Create New</Button>
          <ModalComponent
            isModalVisible={isModalVisible}
            header={(
              <ModalHeader
                selectedRowData={selectedRowData}
                modalOpenedBy={modalOpenedBy}
                entityType={entityType}
              />
)}
            body={(
              <ModalBody
                modalOpenedBy={modalOpenedBy}
                entityType={entityType}
                createModalObject={createModalObject}
                selectedRowData={selectedRowData}
                setNameUpdate={setNameUpdate}
                setBioUpdate={setBioUpdate}
                setWebsiteUpdate={setWebsiteUpdate}
                setSocialsUpdate={setSocialsUpdate}
                nameUpdate={nameUpdate}
                bioUpdate={bioUpdate}
                websiteUpdate={websiteUpdate}
                socialsUpdate={socialsUpdate}
              />
)}
            handleOk={handleOk}
            handleCancel={handleCancel}
            hideOkButton={['view'].includes(modalOpenedBy)}
            hideCancelButton={['view'].includes(modalOpenedBy)}
          />
        </Col>
      </Row>
      <Row justify="end" style={{ paddingTop: '20px' }}>
        <Col>
          <SearchInputComponent
            collection={dataSource}
            setFilter={setEntityFilteredData}
            hasValue={setHasSearchValue}
            filterFor={filterKeys}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={4}>
          <span className={`tabs-txt ${entityType === 'artists' && 'underline'}`} onClick={() => redirectToPath('/entity?type=artists')}>ARTISTS</span>
        </Col>
        <Col className="gutter-row" span={6}>
          <span className={`tabs-txt ${entityType === 'brands' && 'underline'}`} onClick={() => redirectToPath('/entity?type=brands')}>BRANDS</span>
        </Col>
      </Row>
      <br />
      {entityType && entityCollection && (
        <div className="artists-wrapper">
          <TableComponent columnsData={columns} dataSource={entityCollection} />
        </div>
      )}
    </div>
  );
};

export default withRouter(EntityPage);
