import { TEAMS_SETSTATE } from './teamsTypes';

const intialUserState = {
};

const teamsReducer = (state = intialUserState, action: any) => {
  switch (action.type) {
    case TEAMS_SETSTATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default teamsReducer;
