import { httpReq } from '../config/service';

interface EntityDeletePayload {
  entityType: string;
  id: string;
}

interface EntityPayload {
  entityType: string;
  id?: string,
  data: any
}

const getEntity = async (entityType:string):Promise<Array<Object>> => {
  try {
    const { data } = await httpReq.get(`/admin/${entityType}`);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteEntity = async (payload:EntityDeletePayload) => {
  try {
    const { data } = await httpReq.delete(`/admin/${payload.entityType}`);
    return data;
  } catch (err) {
    return err;
  }
};

const createEntity = async (payload:EntityPayload) => {
  try {
    const { data } = await httpReq.post(`/admin/${payload.entityType}`, payload.data);
    return data;
  } catch (err) {
    return err;
  }
};

const updateEntity = async (payload:EntityPayload) => {
  try {
    const { data } = await httpReq.patch(`/admin/${payload.entityType}/${payload.id}`, payload.data);
    return data;
  } catch (err) {
    return err;
  }
};

export {
  getEntity,
  deleteEntity,
  createEntity,
  updateEntity,
};
