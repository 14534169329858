import { httpReq } from '../config/service';

interface PeoplePayload {
  id?: string,
  data: any
};

interface PeopleDeletePayload {
  id: string;
};

const getUsers = async (payload:Object):Promise<Array<Object>> => {
  const { data } = await httpReq.get('/admin/users', payload);
  return data;
};

const createUser = async (payload:PeoplePayload) => {
  try {
    const { data } = await httpReq.post(`/admin/users`, payload.data);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteUser = async (payload:PeopleDeletePayload) => {
  try {
    const { data } = await httpReq.delete(`/admin/users/${payload.id}`);
    return data;
  } catch (err) {
    return err;
  }
};

const updateUser = async (payload:PeoplePayload) => {
  try {
    const { data } = await httpReq.patch(`/admin/users/${payload.id}`, payload.data);
    return data;
  } catch (err) {
    return err;
  }
};

export {
  getUsers,
  createUser,
  deleteUser,
  updateUser
};
