import axios from 'axios';
import store from '../redux/store';
import { START_LOADING, FINISH_LOADING } from '../redux/loading/loadingTypes';

const baseUrl = process.env.REACT_APP_API_URL;
const ax = axios.create();
ax.interceptors.request.use((config) => {
  store.dispatch({
    type: START_LOADING,
  });
  return config;
}, (error) => {
  store.dispatch({ type: FINISH_LOADING });
  return Promise.reject(error);
});

ax.interceptors.response.use((response) => {
  store.dispatch({
    type: FINISH_LOADING,
  });
  return response;
}, (error) => {
  store.dispatch({ type: FINISH_LOADING });
  return Promise.reject(error);
});
class Request {
  async post(path, payload) {
    const user = JSON.parse(localStorage.getItem('user'));
    const reqConfig = {
      params: {},
      headers: {
        Authorization: `Bearer ${user.__raw}`, // the token is a variable which holds the token
      },
    };
    try {
      const result = await axios.post(baseUrl + path, payload, reqConfig);
      return result;
    } catch (err) {
      return err;
    }
  }

  async get(path, params = {}, url = '') {
    const user = JSON.parse(localStorage.getItem('user'));
    const reqConfig = {
      params: {},
      headers: {
        Authorization: `Bearer ${user.__raw}`, // the token is a variable which holds the token
      },
    };
    const uri = url ? url + path : baseUrl + path;
    reqConfig.params = params;
    try {
      const result = await ax.get(uri, reqConfig);
      return result;
    } catch (err) {
      return err;
    }
  }

  async delete(path, params = {}, url = '') {
    const user = JSON.parse(localStorage.getItem('user'));
    const reqConfig = {
      params: {},
      headers: {
        Authorization: `Bearer ${user.__raw}`, // the token is a variable which holds the token
      },
    };
    const uri = url ? url + path : baseUrl + path;
    reqConfig.params = params;
    return await axios.delete(uri, reqConfig);
  }

  async patch(path, payload) {
    const user = JSON.parse(localStorage.getItem('user'));
    const reqConfig = {
      params: {},
      headers: {
        Authorization: `Bearer ${user.__raw}`, // the token is a variable which holds the token
      },
    };
    try {
      const result = await axios.patch(baseUrl + path, payload, reqConfig);
      return result;
    } catch (err) {
      return err;
    }
  }
}

const httpReq = new Request();

export {
  baseUrl,
  httpReq,
};
