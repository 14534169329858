import React from 'react';
import { Input } from 'antd';
// STYLES
import './search-input.scoped.scss';

const SearchInputComponent = ({
  collection,
  setFilter,
  filterFor,
  hasValue,
}) => {
  const filterDataByInput = (e) => {
    const { value } = e.target;
    const filtered = collection.filter((element) => {
      const arr = [];
      filterFor.forEach((key) => arr.push(element[key].toUpperCase().indexOf(value.toUpperCase()) !== -1));
      return arr.some((v) => v === true);
    });
    setFilter(filtered);
    hasValue(value !== '');
  };

  return (
    <div className="search-wrapper" data-testid="search">
      <Input onChange={filterDataByInput} placeholder="Search by name" />
    </div>
  );
};

export default SearchInputComponent;
