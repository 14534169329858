import { PEOPLE_SETSTATE } from './peopleTypes';

const intialUserState = {
};

const peopleReducer = (state = intialUserState, action: any) => {
  switch (action.type) {
    case PEOPLE_SETSTATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default peopleReducer;
