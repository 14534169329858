import React from 'react';
import { Modal } from 'antd';
// STYLES
import './modal.scoped.scss';

const ModalComponent = ({
  isModalVisible,
  header,
  body,
  handleOk,
  handleCancel,
  hideOkButton = false,
  hideCancelButton = false,
}) => (
  <Modal
    title={header}
    visible={isModalVisible}
    onOk={handleOk}
    onCancel={handleCancel}
    okButtonProps={{ hidden: hideOkButton }}
    cancelButtonProps={{ hidden: hideCancelButton }}
  >
    {body}
  </Modal>
);

export default ModalComponent;
