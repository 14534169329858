import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Layout, Spin } from 'antd';
import SideBarComponent from './Sidebar';
import HeaderComponent from './Header';
// STYLES
import './style.scoped.scss';

const { Content } = Layout;

interface LayoutProps {
    menues?: Array<Object>
    children: any,
    logout: any,
    loading: any
}

const LayoutComponent = ({
  menues,
  logout,
  children,
  loading
}:LayoutProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  return (
    <Spin className='spinner' spinning={loading.loading !== loading.totalRequest}>
    <Layout className="layout-wrapper">
      <SideBarComponent
        collapsed={collapsed}
        menues={menues}
      />
      <Layout className="site-layout">
        <HeaderComponent
          collapsed={collapsed}
          toggle={toggle}
          logout={logout}
        />
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,    
}};

export default connect(mapStateToProps)(LayoutComponent);
