import React from 'react';
import { Input } from 'antd';
import * as _ from 'lodash';
// STYLES
import '../entity.scoped.scss';

export const CreateNewModalBody = ({
  entityType,
  createModalObject,
}) => {
  const modalFieldType = {
    artists: ['name', 'biography'],
    brands: ['name', 'description', 'website'],
  };
  return (
    <div className="modal-body-wrapper">
      {entityType && modalFieldType[entityType].map((modalField) => (
        <div className="field-wrapper" key={modalField}>
          <div className="input-lable">{modalField}</div>
          <Input name={modalField} onChange={createModalObject} placeholder={`Enter your ${modalField}`} />
        </div>
      ))}
    </div>
  );
};

export const CreateNewModalHeader = ({ entityType }) => (
  <div>
    Create new
    {entityType}
  </div>
);

export const ViewModalBody = ({
  selectedRowData,
  entityType,
}) => {
  switch (entityType) {
    case 'artists':
      return (
        <div className="view-modal-body-wrapper">
          <p>
            <b>
              Name:
              {selectedRowData.name || '--'}
            </b>
          </p>
          <p>
            <b>Bio:</b>
            {' '}
            {selectedRowData.biography || '--'}
          </p>
        </div>
      );
    case 'brands':
      return (
        <div className="view-modal-body-wrapper">
          <p>
            <b>
              Name:
              {selectedRowData.name || '--'}
            </b>
          </p>
          <p>
            <b>Website:</b>
            {' '}
            {selectedRowData.website || '--'}
          </p>
          <span><b>Socials: </b></span>
          <ul>
            {_.map(selectedRowData.socials, (value, key) => (
              <li>
                <span className="capitalize">
                  {key}
                  :
                </span>
                {' '}
                {value}
              </li>
            ))}
          </ul>

        </div>
      );
    default:
      return <></>;
  }
};

export const ViewModalHeader = ({
  selectedRowData,
  entityType,
}) => (
  <div className="view-modal-header-wrapper">
    <span className="capitalize">{entityType}</span>
    {' '}
    -
    {selectedRowData.name}
  </div>
);

export const UpdateModalBody = ({
  selectedRowData,
  entityType,
  setNameUpdate,
  setBioUpdate,
  setWebsiteUpdate,
  setSocialsUpdate,
  nameUpdate,
  bioUpdate,
  websiteUpdate,
  socialsUpdate,
}) => {
  switch (entityType) {
    case 'artists':
      return (
        <div className="view-modal-body-wrapper">
          <p>
            <b>
              Name:
              <Input value={nameUpdate} onChange={(e) => setNameUpdate(e.target.value)} />
            </b>
          </p>
          <p>
            <b>Bio:</b>
            {' '}
            <Input value={bioUpdate} onChange={(e) => setBioUpdate(e.target.value)} />
          </p>
        </div>
      );
    case 'brands':
      return (
        <div className="view-modal-body-wrapper">
          <p>
            <b>
              Name:
              <Input value={nameUpdate} onChange={(e) => setNameUpdate(e.target.value)} />
            </b>
          </p>
          <p>
            <b>Website:</b>
            {' '}
            <Input value={websiteUpdate} onChange={(e) => setWebsiteUpdate(e.target.value)} />
          </p>
          <span><b>Socials: </b></span>
          <ul>
            {_.map(selectedRowData.socials, (value, key) => (
              <li key={key}>
                <span className="capitalize">
                  {key}
                  :
                </span>
                <Input value={socialsUpdate[key]} onChange={(e) => setSocialsUpdate({ ...socialsUpdate, [key]: e.target.value })} />
              </li>
            ))}
          </ul>

        </div>
      );
    default:
      return <></>;
  }
};

export const ModalBody = ({
  entityType,
  createModalObject,
  selectedRowData,
  modalOpenedBy,
  setNameUpdate,
  setBioUpdate,
  setWebsiteUpdate,
  setSocialsUpdate,
  nameUpdate,
  bioUpdate,
  websiteUpdate,
  socialsUpdate,
}) => {
  switch (modalOpenedBy) {
    case 'createNewButton':
      return (
        <CreateNewModalBody
          entityType={entityType}
          createModalObject={createModalObject}
        />
      );
    case 'view':
      return (
        <ViewModalBody
          selectedRowData={selectedRowData}
          entityType={entityType}
        />
      );
    case 'update':
      return (
        <UpdateModalBody
          selectedRowData={selectedRowData}
          entityType={entityType}
          setNameUpdate={setNameUpdate}
          setBioUpdate={setBioUpdate}
          nameUpdate={nameUpdate}
          bioUpdate={bioUpdate}
          setSocialsUpdate={setSocialsUpdate}
          setWebsiteUpdate={setWebsiteUpdate}
          websiteUpdate={websiteUpdate}
          socialsUpdate={socialsUpdate}
        />
      );
    default:
      return <></>;
  }
};

export const ModalHeader = ({
  modalOpenedBy,
  entityType,
  selectedRowData,
}) => {
  switch (modalOpenedBy) {
    case 'createNewButton':
      return (
        <CreateNewModalHeader
          entityType={entityType}
        />
      );
    case 'view':
      return (
        <ViewModalHeader
          entityType={entityType}
          selectedRowData={selectedRowData}
        />
      );
    case 'update':
      return (
        <ViewModalHeader
          entityType={entityType}
          selectedRowData={selectedRowData}
        />
      );
    default:
      return <></>;
  }
};
