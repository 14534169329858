import React from 'react';
import { Layout } from 'antd';
// STYLES
import './header.scoped.scss';

const { Header } = Layout;

const HeaderComponent = ({
  collapsed,
  toggle,
  logout,
}) => (
  <Header className="site-layout-background" style={{ padding: 0 }}>
    <span className={`material-icons trigger ${collapsed ? 'rotate' : ''}`} onClick={toggle}>
      menu_open
    </span>
    <span className="trigger logout" onClick={() => logout({ returnTo: window.location.origin })}>Logout</span>
  </Header>
);

export default HeaderComponent;
