import { httpReq } from '../config/service';

interface TeamPayload {
  id?: string,
  data: any
};

interface TeamDeletePayload {
  id: string;
};

const getTeams = async (payload):Promise<Array<Object>> => {
  const { data } = await httpReq.get('/admin/teams', payload);
  return data;
};

const deleteTeam = async (payload:TeamDeletePayload) => {
  try {
    const { data } = await httpReq.delete(`/admin/teams/${payload.id}`);
    return data;
  } catch (err) {
    return err;
  }
};

const updateTeam = async (payload:TeamPayload) => {
  try {
    const { data } = await httpReq.patch(`/admin/teams/${payload.id}`, payload.data);
    return data;
  } catch (err) {
    return err;
  }
};

export {
  getTeams,
  deleteTeam,
  updateTeam
};
