import { USERS_SETSTATE } from './usersTypes';

const intialUserState = {
  users: [],
};

const userReducer = (state = intialUserState, action: any) => {
  switch (action.type) {
    case USERS_SETSTATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
