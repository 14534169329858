import { combineReducers } from 'redux';
import usersReducer from './users/usersReducer';
import entitiesReducer from './entities/entitiesReducer';
import peopleReducer from './people/peopleReducer';
import teamsReducer from './teams/teamsReducer';
import loadingReducer from './loading/loadingReducer';

const allReducers = {
  users: usersReducer,
  entities: entitiesReducer,
  people: peopleReducer,
  teams: teamsReducer,
  loading: loadingReducer,
};

const rootReducer = combineReducers(allReducers);
export default rootReducer;
